<template>
  <div class="Dashboard">
    <div class="Dashboard-head_box">
			<div class="Dashboard-head">
				<div class="Dashboard-head__left">
					<div class="title">{{ $t('Farming Pool') }}</div>
					<div class="subtitle">{{ $t('Combine NFT cards to farm every second') }}</div>
					<div class="number">
						<img src="@/assets/BUSD.png" alt="">
						<span>{{ poolAmt }}</span>
					</div>
					<v-btn
							v-if="!is_mobile"
							style="height: 60px !important;width: 300px;"
							color="#EB6930"
							large
							rounded
							to="/app/FarmingPool"
					>{{ $t('GO') }}<v-icon
							dark
							right
					>
						mdi-arrow-right
					</v-icon></v-btn>
				</div>
				<div class="Dashboard-head_right">
					<img src="@/assets/faramingpool01.png" class="Dashboard-head__img" alt/>
				</div>
			</div>
			<v-btn
					v-if="is_mobile"
					style="height: 37px !important;"
					color="#EB6930"
					large
					rounded
					block
					to="/app/FarmingPool"
			>{{ $t('GO') }}<v-icon
					dark
					right
			>
				mdi-arrow-right
			</v-icon></v-btn>
		</div>
		<div class="Dashboard-foot_box">
			<div class="Dashboard-head">
				<div class="Dashboard-head_right">
					<img src="@/assets/faramingpool02.png" class="Dashboard-head__img" alt/>
				</div>
				<div class="Dashboard-head__left">
					<div class="title">{{ $t('Bonuses Pool') }}</div>
					<div class="subtitle">{{ $t('Combine special NFT cards to win huge bonuses') }}</div>
					<div class="number">
						<img src="@/assets/BUSD.png" alt="">
						<span>{{ total_amount }}</span>
					</div>
					<v-btn
							v-if="!is_mobile"
							style="height: 60px !important;width: 300px;"
							color="#EB6930"
							large
							rounded
							to="/app/BonusesPool"
					>{{ $t('GO') }}<v-icon
							dark
							right
					>
						mdi-arrow-right
					</v-icon></v-btn>
				</div>
			</div>
			<v-btn
					v-if="is_mobile"
					style="height: 37px !important;"
					color="#EB6930"
					large
					rounded
					block
					to="/app/BonusesPool"
			>{{ $t('GO') }}<v-icon
					dark
					right
			>
				mdi-arrow-right
			</v-icon></v-btn>
		</div>
		<v-dialog
				v-model="dialog"
				fullscreen
				hide-overlay
		>
			<index />
		</v-dialog>
  </div>
</template>

<script>
import { nftDividendPoolAmount, rewardAmt } from '@/utils/cardFactory'
import Index from '@/views/Index'

export default {
	name: 'Dashboard',
	components: { Index },
	data () {
		return {
			poolAmt: '0.00',
			total_amount: '0.00',
			dialog: false,
		}
	},
	computed: {
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	created () {
		this._poolAmount()
		this._rewardAmt()
	},
	methods: {
		async _poolAmount () {
			this.poolAmt = await nftDividendPoolAmount()
		},
		async _rewardAmt () {
			this.total_amount = await rewardAmt()
		},
		test () {
			console.log(this)
			this.$i18n.locale = 'zhHans'
			this.$vuetify.lang.current = 'zhHans'
		},
	},
}
</script>

<style lang="scss" scoped>
// pc
@media (max-width: 960px) {
	.Dashboard-head {
		.subtitle {
			font-size: 12px;
			line-height: 18px;
			color: #E8E8E8;
		}
		.number {
			img {
				width: 22px;
				margin-right: 8px;
			}
			font-weight: bold;
			font-size: 24px;
			line-height: 36px;
			color: #E8E8E8;
			padding: 28px 0 32px;
		}
		.title {
			font-weight: bold;
			font-size: 20px;
			line-height: 30px;
			color: #E8E8E8;
			margin-bottom: 5px;
		}
		padding-bottom: 18px;
	}
}
// mobile
@media (min-width: 960px) {
	.Dashboard-head {
		.subtitle {
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color: #E8E8E8;
		}
		.number {
			img {
				width: 42px;
				margin-right: 16px;
			}
			padding: 40px 0;
			font-weight: bold;
			font-size: 48px;
			line-height: 72px;
			color: #E8E8E8;
		}
		.title {
			font-weight: bold;
			font-size: 24px;
			line-height: 36px;
			color: #E8E8E8;
			margin-bottom: 6px;
		}
	}
}
.Dashboard {
	.number {
		display: flex;
		align-items: center;
	}
	.Dashboard-head_right {
		img {
			width: 100%;
		}
		max-width: 508px;
		width: 100%;
	}
	.Dashboard-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 948px;
		margin: 0 auto;
	}
	.Dashboard-foot_box {
		background-color: #49505F;
		padding: 40px 25px;
	}
	.Dashboard-head_box {
		background-color: #3D4853;
		padding: 40px 25px;
	}
}
</style>
